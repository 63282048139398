import React from 'react'
import './footer.css'
import {BsInstagram} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href='#'className='footer__logo'>DavidDarkoh</a>
      <ul className='permalinks'>
      <li><a href="#">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#experience">Experience</a></li>
      <li><a href="#services">Services</a></li>
      <li><a href="#portfolio">Portfolio</a></li>
      <li><a href="#contact">Contacts</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.instagram.com/ghanaian_goku/"><BsInstagram /></a>
        <a href="https://twitter.com/ghanaian_goku"><BsTwitter /></a>
      </div>
      <div className="footer__copyright"></div>
        <small>&copy; David Darkoh. All rights reserved.</small>
    </footer>
  )
}

export default Footer