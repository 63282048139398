import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'
const Services = () => {
  return (
    <section id= 'services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className='container services__container'>
        <article className="service">
          <div className='service__head'>
            <h3>Ui/UX Design</h3>
          </div>
          <ul className='service__list'>
          <li>
              <BiCheck className='service__list-icon' />
              <p>Clean visual and interactive design.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Design graphic user interface elements.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Build page navigation buttons and search field.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Develope UI mockups and prototypes.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Create original graphic designs.</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="service">
          <div className='service__head'>
            <h3>Web Development</h3>
          </div>
          <ul className='service__list'>
          <li>
              <BiCheck className='service__list-icon' />
              <p>Write well designed testable, efficient code.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Create website layout/user interface by using HTML5 CSS3 and JavaScript.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Integrate data from various back-end services and databases.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Gather and refine specifications and requirements based on technical needs.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Great communication skills amongst team and clients to produce the best projects.</p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}
        <article className="service">
          <div className='service__head'>
            <h3>Fashion Designer</h3>
          </div>
          <ul className='service__list'>
          <li>
              <BiCheck className='service__list-icon' />
              <p>Create beautiful clothing designs for daily wear.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Study fashion trends and anticipate designs that will appeal to consumers.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Use computer-aided design (CAD) programs to create designs.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Sketch designs, select fabrics and patterns.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Work with other designers or team members to create prototype designs.</p>
            </li>
          </ul>
        </article>
        {/* END OF FASHION DESIGNER */}
      </div>
    </section>
  )
}

export default Services