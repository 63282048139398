import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
const Portfolio = () => {
  return (
    <section id= 'portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG1} alt=""/>
            </div>
            <h3>Chinese Restaurant Website </h3>
            <div className="portfolio__item-cta">
            <a href="https://github.com/DXD434/Chinese-restaurant-website" className='btn' target='_blank'>Github</a>
            <a href="https://dxd434.github.io/Chinese-restaurant-website/module-5-solution/index.html#" classNmame='btn btn-primary' target='_blank'>Live Demo</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG2} alt=""/>
            </div>
            <h3>E-commerce Website </h3>
            <div className="portfolio__item-cta">
            <a href="https://www.hylyphe.com/" className='btn' target='_blank'>Hylyphe</a>
            <a href="https://www.hylyphe.com/about/" classNmame='btn' target='_blank'>Live Demo</a>
            </div>
          
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG3} alt=""/>
            </div>
            <h3>Weather Application </h3>
            <div className="portfolio__item-cta">
            <a href="https://github.com/DXD434/WeatherApp" className='btn' target='_blank'>Github</a>
            <a href="https://dxd434.github.io/WeatherApp/" classNmame='btn' target='_blank'>Live Demo</a>
            </div>
          
        </article>
      </div>
    </section>
  )
}

export default Portfolio