import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'
const Experience = () => {
  return (
    <section id= 'experience'>
      <h5>My Skills</h5>
      <h2>Experience</h2>


      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article classname="experience__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>HTML</h4>
               </div>
            </article>
            <article classname="experience__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>CSS</h4>
               </div>
            </article>
            <article classname="experience__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>JavaScript</h4>
               </div>
            </article>
            <article classname="experience__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>Bootstrap</h4>
               </div>
            </article>
            <article classname="experience__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>React</h4>
               </div>
            </article>
          </div>
        </div>
        {/* END OF FRONTEND */}
        <div className="experience__backend">
        <h3>Backend Development</h3>
          <div className="experience__content">
            <article classname="experiene__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>Python</h4>
               </div>
            </article>
            <article classname="experiene__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>C</h4>
               </div>
            </article>
            <article classname="experience__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>SQL</h4>
               </div>
            </article>
            <article classname="experience__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>Flask</h4>
               </div>
            </article>
            <article classname="experience__details">
               <BsPatchCheckFill className='experience__details-icon' />
               <div>
               <h4>Node Js</h4>
               </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience